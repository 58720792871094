import React, { useState, useEffect } from 'react';
import GlobalStyle from "../styles/global";
import Header from "../components/header";
import styled from 'styled-components';
import { IconInput } from '../styles/global';
import { PrimaryColor, SecondaryColor, TextColor, TextColorLight, TextColorDark, BackgroundColor, BackgroundColorLight, Border } from "../styles/global";
import logo from '../assets/icons/logo-banco-da-felicidade.svg';
import { SvgKey, SvgUser } from '../assets/icons';
import { Container } from '../styles/styles';
import { loginUser } from '../controllers/userController'; // Importe o controlador
import Inputmask from 'inputmask';
import { FormContainer, LoginContainer, LoginSection } from '../styles/styles';


function Login() {
  const [cpf, setCpf] = useState('');
  const [senha, setSenha] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    Inputmask("999.999.999-99").mask(document.getElementById("cpf"));
  }, []);

  const removeMaskCPF = (cpf) => {
    return cpf.replace(/\D/g, ''); // Remove tudo que não é número
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await loginUser(removeMaskCPF(cpf), senha); // Utilize o controlador aqui
      // Armazenar o usuário no localStorage ou cookies
      localStorage.setItem('user', JSON.stringify(response.usuario));
      setErrorMessage('');
      // Redirecionar para a página principal ou painel
      window.location.href = '/';
    } catch (error) {
      setErrorMessage(error.mensagem);
    }
  };

  return (
    <>
      {/* <Header /> */}
      <GlobalStyle />
      <LoginSection>
        <LoginContainer>
          <img src={logo} alt="Logo Banco da Felicidade" />
          <h2>Olá, consulte o seu porquinho!</h2>
          <form onSubmit={handleSubmit}>
            <IconInput>
              <SvgUser />
              <input
                type="text"
                id="cpf"
                name="cpf"
                placeholder="CPF"
                value={cpf}
                autoComplete='off'
                onChange={(e) => setCpf(e.target.value)}
                required
              />
            </IconInput>
            <IconInput>
              <SvgKey />
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Senha"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
                required
              />
            </IconInput>
            <FormContainer direction="column">
              <a href="/recuperar-senha">Esqueci minha senha</a>
              <button type="submit">Entrar</button>
            </FormContainer>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          </form>
        </LoginContainer>
      </LoginSection>
    </>
  );
}

export default Login;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/header';
import GlobalStyle from '../../styles/global';
import * as styles from './styles';
import { Container, Content, ContentSection } from '../../styles/styles';
import { BackBar, BackButton, ErrorComponent } from '../../styles/global';
import { SvgUser } from '../../assets/icons';
import { createUser } from '../../controllers/userController'; // Função que vamos criar
import { Employee, Form, InputField, SelectField, RequiredText, ErrorMessage, SubmitButton, Aviso } from './styles';
import Inputmask from 'inputmask';

const UserAdd = () => {
    const user = JSON.parse(localStorage.getItem('user')); // Usuário autenticado
    const navigate = useNavigate();

    // Definindo os estados para os campos do formulário
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [confirmarSenha, setConfirmarSenha] = useState('');
    const [cpf, setCpf] = useState('');
    const [cargo, setCargo] = useState('');
    const [unidade, setUnidade] = useState('');
    const [secao, setSecao] = useState('');
    const [setor, setSetor] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


    useEffect(() => {
        Inputmask("999.999.999-99").mask(document.getElementById("cpf"));
    }, []);

    const removeMaskCPF = (cpf) => {
        return cpf.replace(/\D/g, ''); // Remove tudo que não é número
    };


    // Função para lidar com o envio do formulário
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (senha !== confirmarSenha) {
            setErrorMessage('As senhas não coincidem!');
            return;
        }

        const cpfSemMascara = removeMaskCPF(cpf);

        const formData = {
            nome,
            email,
            senha,
            cpfSemMascara,
            cargo,
            unidade,
            secao,
            setor,
        };

        try {
            // Faz a requisição para criar um novo usuário
            await createUser(formData); // Função que vamos criar no `userController`
            alert('Usuário criado com sucesso!');
            navigate('/'); // Redireciona após a criação
        } catch (error) {
            setErrorMessage('Erro ao criar o usuário: ' + error.mensagem);
        }
    };

    return (
        <>
            <Header />
            <GlobalStyle />
            <Container fullWidth direction="column" align="center" justify="center">
                <Container direction="column" width="100%">
                    <BackBar>
                        <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
                        <BackButton onClick={() => navigate('/')}>X</BackButton>
                    </BackBar>

                    <ContentSection fullWidth>
                        <Content light>
                            <Form onSubmit={handleSubmit}>
                                <h2>Adicionar Usuário</h2>
                                <Container direction="column" width="80%" gap="15px">

                                    <InputField
                                        type="text"
                                        placeholder="Nome"
                                        value={nome}
                                        onChange={e => setNome(e.target.value)}
                                        required
                                    />
                                    <RequiredText>Obrigatório *</RequiredText>

                                    <InputField
                                        type="text"
                                        placeholder="CPF"
                                        id="cpf"
                                        value={cpf}
                                        onChange={e => setCpf(e.target.value)}
                                        required
                                    />
                                    <RequiredText>Obrigatório *</RequiredText>

                                    <InputField
                                        type="email"
                                        placeholder="Email do usuário"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        required
                                    />
                                    <RequiredText>Obrigatório *</RequiredText>

                                    <InputField
                                        type="password"
                                        placeholder="Senha"
                                        value={senha}
                                        onChange={e => setSenha(e.target.value)}
                                        required
                                    />
                                    <RequiredText>Obrigatório *</RequiredText>

                                    <InputField
                                        type="password"
                                        placeholder="Confirmar Senha"
                                        value={confirmarSenha}
                                        onChange={e => setConfirmarSenha(e.target.value)}
                                        required
                                    />
                                    <RequiredText>Obrigatório *</RequiredText>



                                    <SelectField value={cargo} onChange={e => setCargo(e.target.value)} required>
                                        <option value="">Selecione o Cargo</option>
                                        <option value="Membro da Diretoria">Membro da Diretoria</option>
                                        <option value="Gerente Geral">Gerente Geral</option>
                                        <option value="Gerente Operacional">Gerente Operacional</option>
                                        <option value="Gerente Corporativo">Gerente Corporativo</option>
                                        <option value="Gestor">Gestor</option>
                                        <option value="Coordenador Corporativo">Coordenador Corporativo</option>
                                        <option value="Coordenador">Coordenador</option>
                                        <option value="Supervisor">Supervisor</option>
                                        <option value="Colaborador">Colaborador</option>
                                    </SelectField>
                                    <RequiredText>Obrigatório *</RequiredText>

                                    <SelectField value={unidade} onChange={e => setUnidade(e.target.value)} required>
                                        <option value="">Selecione a Unidade</option>
                                        <option value="Holding">Holding</option>
                                        <option value="Alegro">Alegro</option>
                                        <option value="Alexânia">Alexânia</option>
                                        <option value="Araxá">Araxá</option>
                                        <option value="Atibaia">Atibaia</option>
                                        <option value="Caeté">Caeté</option>
                                    </SelectField>
                                    <RequiredText>Obrigatório *</RequiredText>

                                    <InputField
                                        type="text"
                                        placeholder="Seção"
                                        value={secao}
                                        onChange={e => setSecao(e.target.value)}
                                        required
                                    />
                                    <RequiredText>Obrigatório *</RequiredText>

                                    <InputField
                                        type="text"
                                        placeholder="Setor"
                                        value={setor}
                                        onChange={e => setSetor(e.target.value)}
                                        required
                                    />
                                    <RequiredText>Obrigatório *</RequiredText>

                                    {/* {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>} */}
                                    {errorMessage && <ErrorComponent message={errorMessage} duration={8000} />}

                                    <SubmitButton type="submit">
                                        Confirmar
                                    </SubmitButton>
                                </Container>

                            </Form>
                        </Content>
                    </ContentSection>
                </Container>
            </Container>
        </>
    );
};

export default UserAdd;

import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../components/header';
import GlobalStyle from '../styles/global';
import SelecionarEmocionador from '../components/SelecionarEmocionador';
import SelecionarMoedas from '../components/SelecionarMoedas';
import JustificarEmocao from '../components/JustificarEmocao';
import Confirmacao from '../components/Confirmacao';
import { Container } from '../styles/styles';
import { PrimaryColor } from '../styles/global';
import { API_URL } from '../controllers/api';

const BackBar = styled(Container)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 60px;
  max-width: 100%;
`;

const BackButton = styled.a`
  display: flex;
  align-items: center;
  color: ${PrimaryColor};
  text-decoration: none;
  font-size: 22px;
  margin-bottom: 20px;
  width: fit-content;
`;

function Emocionar({ onClose }) {
  const [step, setStep] = useState(1);
  const [emocionador, setEmocionador] = useState(null);
  const [moedas, setMoedas] = useState([]); // Array de moedas [{ valor: 1, quantidade: 5 }, { valor: 10, quantidade: 2 }]
  const [justificationData, setJustificationData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const user = JSON.parse(localStorage.getItem('user'));

  const handleSelectEmocionador = (selectedEmocionador) => {
    setEmocionador(selectedEmocionador);
    setStep(2);
  };

  const handleConfirmMoedas = (selectedMoedas) => {
    setMoedas(selectedMoedas); // Recebe um array com moedas de diferentes valores e quantidades
    setStep(3);
  };

  const handleJustificationSubmit = async (justification) => {

    setJustificationData(justification);

    try {
      const formData = new FormData();
      formData.append('distribuidorId', user.idusuario); // ID de quem distribui
      formData.append('receptorId', emocionador.id); // ID de quem recebe
      formData.append('moedas', JSON.stringify(moedas)); // Converte o array de moedas para string
      formData.append('motivo', justification.reason); // Justificativa fornecida
      formData.append('descricao', justification.justification); // Descrição fornecida
      formData.append('data_emocao', justification.date); // Data fornecida
      if (justification.file) {
        formData.append('file', justification.file); // Foto da entrega, se houver
      }

      const response = await fetch(API_URL + '/emocionarMoedas', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();

      if (response.ok) {
        setStep(4); // Vai para a etapa de confirmação
      } else {
        setErrorMessage(result.mensagem || "Ocorreu um erro ao enviar os dados.");
      }
    } catch (error) {
      setErrorMessage(`Erro de comunicação com a API: ${error.message}`);
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleClose = () => {
    window.location.href = '/';
  };

  return (
    <>
      <Header />
      <GlobalStyle />
      <Container fullWidth direction="column" align="center" justify="center">
        {step === 1 && <SelecionarEmocionador onSelect={handleSelectEmocionador} onClose={handleClose} />}
        {step === 2 && (
          <SelecionarMoedas
            emocionador={emocionador}
            onConfirm={handleConfirmMoedas}
            onBack={handleBack}
            errorMessage={errorMessage}
            onClose={handleClose}
          />
        )}
        {step === 3 && (
          <JustificarEmocao
            emocionador={emocionador}
            moedas={moedas}
            onSubmit={handleJustificationSubmit}
            onBack={handleBack}
            errorMessage={errorMessage}
            onClose={handleClose}
          />
        )}
        {step === 4 && <Confirmacao emocionador={emocionador} moedas={moedas} onClose={handleClose} />}
      </Container>
    </>
  );
}

export default Emocionar;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Header from '../../components/header';
import GlobalStyle from '../../styles/global';
import breakpoints from '../../styles/breakpoints';
import placeholderImage from '../../assets/images/placeholder.png';
import { Container, Content, ContentSection } from '../../styles/styles';
import { BackBar, BackButton, ErrorComponent } from '../../styles/global';
import { getPremioById, resgatarPremio } from '../../controllers/premioController'; // Descomentar quando a API estiver disponível
import { PremioContainer, ImageContainer, DetailsContainer, Price, Description, AddToCartButton, QuantityContainer, QuantityControl, QuantityButton, Quantity, ErrorMessage } from './styles';
import PremioFooter from './premio-adm-footer';
import { API_URL } from '../../controllers/api';

const user = JSON.parse(localStorage.getItem('user'));

const PremioDetails = () => {
    const { id } = useParams(); // Captura o ID do prêmio da URL
    const navigate = useNavigate();
    const [premio, setPremio] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [errorMessage, setErrorMessage] = useState('');
    const userId = user.idusuario; // ID do usuário logado

    useEffect(() => {
        // Comentando a chamada à API e usando um prêmio de teste
        getPremioById(id)
            .then(setPremio)
            .catch(() => setErrorMessage('Erro ao carregar o prêmio.'));
    }, [id]);

    const handleQuantityChange = (amount) => {
        setQuantity(prevQuantity => Math.max(1, prevQuantity + amount));
    };

    const handleAddToCart = async () => {
        try {
            console.log('Resgatando prêmio', id, userId, quantity);
            await resgatarPremio(id, userId, quantity); // Chama a função do controller
            navigate('/resgate-concluido', { state: { premio, quantity } });  // Redireciona para a página de sucesso
        } catch (error) {
            setErrorMessage(error.message || 'Erro ao resgatar o prêmio.');
        }
    };

    if (!premio) {
        return (
            <>
                <Header />
                <GlobalStyle />
                <ContentSection fullWidth height="100vh">
                    <Content direction="column" align="center">
                        <ErrorMessage>{errorMessage || 'Prêmio não encontrado.'}</ErrorMessage>
                        <BackButton onClick={() => navigate('/')}>← Voltar</BackButton>
                    </Content>
                </ContentSection>
            </>
        );
    }

    return (
        <>
            <Header />
            <GlobalStyle />
            <Container fullWidth direction="column" align="center" justify="center">
                <Container direction="column" width="100%">
                    <BackBar>
                        <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
                        <BackButton onClick={() => navigate('/')}>X</BackButton>
                    </BackBar>

                    <ContentSection fullWidth>
                        <Content maxWidth="900px">
                            <PremioContainer>
                                <ImageContainer>
                                    <img src={API_URL + premio.url_imagem || placeholderImage} alt={premio.nome} />
                                </ImageContainer>
                                <DetailsContainer>

                                    <Container padding="0" direction="column" gap="20px">
                                        <Container direction="column" padding="0" gap="0">
                                            <h2>{premio.nome}</h2>
                                            <Price>{premio.valor}</Price>
                                        </Container>
                                        <Description>{premio.descricao}</Description>
                                    </Container>

                                    {/* <ErrorMessage>{errorMessage ? `Erro: ${errorMessage}` : ""}</ErrorMessage> */}
                                    {errorMessage && <ErrorComponent message={errorMessage} duration={8000} />}

                                    <Container fullWidth gap="20px" padding="0" justify="space-between" align="end">
                                        <AddToCartButton onClick={handleAddToCart}>Finalizar compra</AddToCartButton>

                                        <QuantityContainer>
                                            <p>Quantidade</p>
                                            <QuantityControl>
                                                <QuantityButton onClick={() => handleQuantityChange(-1)}>-</QuantityButton>
                                                <Quantity>{quantity}</Quantity>
                                                <QuantityButton onClick={() => handleQuantityChange(1)}>+</QuantityButton>
                                            </QuantityControl>
                                        </QuantityContainer>

                                    </Container>
                                </DetailsContainer>
                            </PremioContainer>
                        </Content>
                    </ContentSection>
                </Container>
            </Container>

            {user.nivel == 1 ? (<PremioFooter idpremio={id} />) : null}
            
        </>
    );
};

export default PremioDetails;

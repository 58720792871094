import styled from "styled-components";
import { Container } from "../../styles/styles";
import { PrimaryColor, SecondaryColor, TextColor, TextColorLight, TextColorDark ,BackgroundColor, BackgroundColorLight, Border, Accent } from "../../styles/global";
import breakpoints from "../../styles/breakpoints";



export const Employee = styled(Container)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  background-color: ${SecondaryColor};
  color: ${TextColorLight};
  border-radius: 8px;
  width: fit-content;
  padding: 20px;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;

  p, h3 { color: ${TextColorLight}; }
  p, small { font-size: 14px; }
  p { font-weight: 500; }
  small { font-weight: lighter; }

  .employee-details {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 0px;
    flex: 1;
    text-align: left;
    small { width: max-content; }
  }

  .employee-icon-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: ${BackgroundColor};
    text-align: center;
    padding: 22px;
  }
  
  .employee-icon {
    color: ${SecondaryColor};
  }
`;

export const EmployeeActionItem = styled(Container)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;

  p{font-weight: 400;}
  span{font-size: 25px; width:auto;}
  svg{color: ${PrimaryColor};width:auto;}

  .employee-icon-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: ${SecondaryColor};
    text-align: center;
    padding: 10px;
    svg{color: ${TextColorLight};}
  }
  
  .employee-icon {
    color: ${TextColorLight};
    
  }

  &:hover {
    background-color: ${BackgroundColorLight};
    p,svg{color: ${TextColorLight};}
  }
`;

export const LogoutButton = styled.button`
  background-color: ${BackgroundColor};
  border: solid 2px ${PrimaryColor};
  color: ${TextColor};
  padding: 5px 60px;
  border-radius: 50px;
  font-size: 18px;
  cursor: pointer;
  align-self: center;

  &:hover {
    background-color: ${PrimaryColor};
    color: ${TextColorLight};
  }
`;

export const RequiredText = styled.p`
  font-size: 12px;
  color: ${TextColorLight}!important;
  margin-top: -15px;
  align-self: flex-start;
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 10px;
  color: ${TextColorLight};
`;

export const Aviso = styled(Container)`
    background-color: ${BackgroundColorLight};
    p, a{color: ${TextColorLight};}
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 8px;
    font-size: 14px;
`;


// edit.js

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background-color: ${SecondaryColor};
  border-radius: 10px;
  align-items: center;

  h2{color: ${TextColorLight};}
`;

export const InputField = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  background-color: ${TextColorLight};
  color: ${TextColor};

  &::placeholder {
    color: ${TextColor};
  }

  &:disabled {
    color: ${TextColorDark};
    background-color: #cacaca;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  min-height: 130px;
  color: ${TextColor};

  &::placeholder {
    color: ${TextColor};
  }
`;

export const SelectField = styled.select`
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  background-color: ${TextColorLight};
  color: ${TextColor};
`;

export const SubmitButton = styled.button`
  background-color: ${PrimaryColor};
  color: ${TextColorLight};
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    background-color: ${BackgroundColor};
    color: ${TextColor};
  }
`;

//view.js
export const UserListTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;

    thead {
        background-color: ${PrimaryColor};
        color: ${TextColorLight};
        border-radius: 8px;

        th {
            padding: 8px;
            text-align: left;
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid ${Accent};
            &:hover {
                background-color: ${Accent};
            }

            td {
                padding: 5px;
                color: ${TextColorDark};
                font-size: 13px;

                button {
                    background: none;
                    border: none;
                    cursor: pointer;
                    padding: 5px;
                }

                svg {
                    width: 20px;
                    height: 20px;
                    color: ${PrimaryColor};
                }

                svg:hover {
                    color: ${TextColorDark};
                }
            }
        }
    }
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../components/header';
import GlobalStyle from '../styles/global';
import SelecionarEmocionador from '../components/SelecionarEmocionador';
import SelecionarMoedas from '../components/SelecionarMoedas';
import ConfirmacaoADM from '../components/ConfirmacaoADM';
import { Container, ContentSection, Content } from '../styles/styles';
import { PrimaryColor, TextColor, TextColorLight } from '../styles/global';
import { API_URL } from '../controllers/api';

const BackBar = styled(Container)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 60px;
  max-width: 100%;
`;

const BackButton = styled.a`
  display: flex;
  align-items: center;
  color: ${PrimaryColor};
  text-decoration: none;
  font-size: 22px;
  margin-bottom: 20px;
  width: fit-content;
`;


function DistribuirMoedas({ onClose }) {
  const [step, setStep] = useState(1);
  const [emocionador, setEmocionador] = useState(null);
  const [moedas, setMoedas] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const user = JSON.parse(localStorage.getItem('user'));

  const handleSelectEmocionador = (emocionador) => {
    setEmocionador(emocionador);
    setStep(2);
  };

  const handleConfirmMoedas = async (moedas) => {
    setMoedas(moedas);

    try {
      const data = {
        distribuidorId: user.idusuario, // ID de quem distribui
        receptorId: emocionador.id, // ID de quem recebe
        moedas: moedas // Não é necessário stringificar, pois fetch lida com objetos automaticamente
      };

      console.log(user.idusuario, emocionador.id, moedas);
      
      const response = await fetch(API_URL + '/distribuirMoedas', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Define que o corpo da requisição será JSON
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (response.ok) {
        setStep(3); // Vai para a etapa de confirmação
      } else {
        setErrorMessage(result.mensagem || "Ocorreu um erro ao enviar os dados.");
      }
    } catch (error) {
      setErrorMessage(`Erro de comunicação com a API: ${error.message}`);
    }
  };

  const handleBack = () => {
    if (step === 2) {
      setStep(1);
    }
  };

  const handleClose = () => {
    window.location.href = '/';
  };

  return (
    <>
    <Header />
      <GlobalStyle />
      <Container fullWidth direction="column" align="center" justify="center">
        {step === 1 && <SelecionarEmocionador onSelect={handleSelectEmocionador} onClose={handleClose}/>}
        {step === 2 && (
          <SelecionarMoedas
            emocionador={emocionador}
            onConfirm={handleConfirmMoedas}
            onBack={handleBack}
            errorMessage={errorMessage}
            onClose={handleClose}
          />
        )}
        {step === 3 && <ConfirmacaoADM emocionador={emocionador} moedas={moedas} onClose={handleClose} />}
    </Container>
    </>
  );
}

export default DistribuirMoedas;

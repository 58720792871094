import styled from "styled-components";
import GlobalStyle from "./global";
import { ContentWidth } from "./global";
import { PrimaryColor, SecondaryColor, TextColor, TextColorLight, TextColorDark,BackgroundColor, BackgroundColorLight, Border } from "./global";
import breakpoints from './breakpoints';

// General Container
const Container = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.justify || 'flex-start'};
  align-items: ${props => props.align || 'stretch'};
  flex-wrap: ${props => props.wrap || 'nowrap'};
  background-color: ${props => props.bgColor || 'transparent'};
  padding: ${props => props.padding || '10px'};
  max-width: ${props => props.fullWidth ? '100%' : ContentWidth};
  width: ${props => props.fullWidth ? '100%' : (props.width || 'auto')};
  height: ${props => props.height || 'auto'};
  margin: ${props => props.margin || '0'};
  gap: ${props => props.gap || '2px'};
  justify-self: center;
  align-self: ${props => props.alignSelf || 'auto'};
  box-shadow: ${props => props.shadow ? '0 0 10px rgba(0,0,0,0.1)' : 'none'};
  z-index: ${props => props.zIndex || 'auto'};

  /*    
  @media (breakpoints.md) {
    max-width: 100%;
  }
    */

h1, h2, h3, h4, h5, h6, p{
    color: ${props => props.light ? TextColorLight : TextColor};
    width: auto;
}

p{
    color: ${props => props.light ? TextColorLight : TextColor};
    width: auto;
}

`;

// UserSection Section
const UserSection = styled(Container)`
  background-color: ${BackgroundColorLight};
  color: ${TextColorLight}!important;
  padding: 60px;

  img{
    width: 90%;
  }

  img:hover{
    rotate: 360deg;
    transition: 1s;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 20px;
  }
`;

// UserInfo Section
const UserInfo = styled(Container)`
    width: 100%; 
    flex-direction: column;
    align-items: start;
    justify-content: space-around;
    padding: 0;
`;

// Balance Section
const BalanceSection = styled(Container)`
  width: 100%;
  padding: 0;
`;

const BalanceItem = styled(Container)`
  flex-direction: column;
  text-align: left;
  padding: 0;
  gap: 0;
  color: ${TextColor};

  h2{font-size: 26px;}

  .balanceCoin{
    display: flex;
    align-items: center;
    justify-content: start;
    :first-child{
      margin-left: 0px;
    }
  }

  .balanceCoin img{
    width: 40px;
    margin-left: 5px;
    margin-right: 3px;
    margin-bottom: 2px;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    flex: 1;
    h2{font-size: 24px;}
  }
`;

export const HomeSection = styled(Container)`
  justify-content: space-around;
  width: 100%;

  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Section01 = styled(Container)`
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 50px;

  @media screen and (max-width: ${breakpoints.md}) {
    gap: 20px;
    width: 100%;
  }
`;

export const Section02 = styled(Container)`
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

const ContentSection = styled(Container)`
  align-items: center  ;
  justify-content: center;
`;

const Content = styled(Container)`
  max-width: ${props => props.maxWidth || '600px;'};
  width: 100%;
  flex-direction: column;
  justify-content: center;
`;

const MainMenuSection = styled(Container)`
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
    color: ${TextColor};

    a{
        display: flex;
        flex-direction: column;
    }

    :hover{
        color: ${SecondaryColor};
        svg {
          fill: ${TextColorLight};
          color: ${TextColorLight};
        }
    }

    img{width:50px}

    img:hover{
        filter: contrast(150%);	;
        transition: 0.5s;
        cursor: pointer;
    }
    
`;

const MainMenuIcon = styled(Container)`
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  align-self: center;
  background: ${PrimaryColor};
  fill: ${TextColorLight};
  color: ${TextColorLight};
  cursor: pointer;
  transition: 0.5s;
  border-radius: 50%;
  svg {
    width: 25px;
    height: 25px;
    :hover {
      fill: ${TextColorLight};
      color: ${TextColorLight};
    }
  }
`;

// History Section
const HistorySection = styled(Container)`
  flex-direction: column;
  width: 100%;
  background-color: ${BackgroundColor};
  padding: 40px;

  .historico{
  max-height: 600px;
  overflow-y: auto;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 10px;
  }
`;

const HistoryItem = styled(Container)`
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid ${Border};

  img {
    width: 35px;
    height: 35px;
  }

  p {
    margin: 0;
    font-size: 14px;
    color: ${TextColor};
  }

  .item-type {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 2;
  }

  .item-time-date {
    display: flex;
    justify-content: start;
    gap: 10px;
    flex: 1;
    color: ${TextColor};
    font-size: 12px;
  }

  .item-time-date small {
    width: fit-content;
  }

  .item-amount {
    font-weight: bold;
    color: ${props => props.amountColor || TextColor};
    flex: 0.5;
    text-align: right;
  }
`;

//Login

export const LoginSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

export const LoginContainer = styled.div`
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  color: ${TextColor};
  gap: 10px;

  h2 { margin-bottom: 20px; }

  img {
    margin-bottom: 20px;
    width: 150px;

  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;

    input {
      padding: 5px 10px;
      border: 1px solid ${Border};
      border-radius: 5px;
      font-size: 16px;
      font-weight: 500;
      width: 100%;
    }

    button {
      padding: 5px 80px;
      background-color: ${PrimaryColor};
      color: ${TextColorLight};
      border: none;
      border-radius: 50px;
      cursor: pointer;
      font-size: 16px;
    }

    button:hover {
      background-color: ${PrimaryColor};
      opacity: 0.9;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    img{width: 120px;}
  }

`;

export const FormContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  margin-top: -20px;

  a {
    color: ${TextColorDark};
    text-decoration: underline;
    font-size: 14px;
    align-self: flex-end;
    text-align: right;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export {
  Container,
  UserSection,
  UserInfo,
  BalanceSection,
  BalanceItem,
  ContentSection,
  Content,
  MainMenuSection,
  MainMenuIcon,
  HistorySection,
  HistoryItem
};
